.lkMidjourneyPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  background: var(--background-bg-light, #192033);
  padding: 24px;
  .lkMidjourneyPageAlert{
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.63px;
    letter-spacing: 0%;
    color: var(--Text-text-placeholder, #979AA4);
  }
  .lkMidjourneyPageContainer {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1120px) {
      flex-wrap: wrap;
    }
    width: 100%;
    gap: 32px;
    .lkMidjourneyPagePropsContainer{
      display: flex;
      flex-direction: row;
      min-width: 372px;
    }
    .lkMidjourneyPageProjectContainer{
      display: flex;
      flex-direction: column;
      // width: 80%;
      overflow-y: scroll;
      min-width: 400px;
    }

  }

}