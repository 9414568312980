.mjProjectItem {
    display: flex;
    flex-direction: column;
    width: 421;
    // height: 388;
    border-radius: 6px;
    border-width: 1px;
    background: var(--background-bg-light, #192033);
    border: 1px solid var(--Border-border-light, #6F748380);
    padding: 0px 8px 8px 8px;
    gap: 8px;
    margin-bottom: 24px;
    justify-content: space-between;
    .mjProjectItemErrorContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 300px;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: center;
      gap: 9px;
      .mjProjectItemErrorLabel{
        font-family: Montserrat;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0%;
        color: var(--Text-text-error, #D83030);

      }
      .mjProjectItemErrorText{
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0%;
        text-align: center;
        color: var(--Text-text-black, #121724);

      }
    }
  .mjProjectItemImage{
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    .mjProjectItemImagePercent{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
  .mjProjectItemButtons{
    display: flex;
    flex-direction: column;
    gap: 4px;
    .mjProjectItemButtonsLine{
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
    .flexbtn{
      display: flex;
      flex-grow: 1;
    }
    .crossRed {
      .customIcon {
        path {
          fill: var(--Icon-icon-error, #DF7171);

        }
      }
    }
  }
}

.midjourneyVariation{
  display: flex;
  width: 240;
  padding-bottom: 20px;
  border-radius: 2px;
  flex-direction: column;

  &.light {
    background: var(--background-bg-hint);
  }
  &.dark {
    background: var(--background-bg-hint, #1E283ECC);
  }

  .midjourneyVariationLabel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    margin-top: 9px;
    margin-bottom: 9px;
    .midjourneyVariationLabelText{
      padding-left: 24px;
      font-family: Montserrat;
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 0%;
      vertical-align: middle;

    }
    .midjourneyVariationLabelClose{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      cursor: pointer;
    }
  }
  .midjourneyVariationBody{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }
}