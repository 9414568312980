.lkVideoDid {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .lkVideoDidTextContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 179px;
    padding: 24px 16px 24px 16px;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid var(--Border-border-light, #6F748380);
    background: var(--background-bg-light, #192033);

    .lkVideoDidTextLabel{
      display: flex;
      color: var(--Text-text-special, #7479F6);
      //styleName: Title/Text - Title 4;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

    }
    .lkVideoDidTextarea{
      display: flex;
      //styleName: Body/Body text medium 14;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      textarea{
        max-height: 400px;
      }

    }
  }
  .lkVideoDidPropertiesContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 24px;
    background: var(--background-bg-light, #192033);
    border: 1px solid var(--Border-border-light, #6F748380);
    padding: 24px 16px 24px 16px;
    flex: 1;
    border-radius: 16px;
    width: 100%;
    .lkVideoDidPropertiesLine{
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      gap: 24px;
      .lkVideoDidPropertyContainer{
        display: flex;
        // width: 32%;
        width: auto;
        flex: 1;
        flex-direction: column;
        background: var(--background-bg-pages, #121724);
        height: 144px;
        // padding: 8px 16px 8px 16px;
        border-radius: 8px;
        justify-content: flex-start;
        &.last{
          background: var(--background-bg-light, #192033); 
          gap: 24px;
        }
        // gap: 16px;
        .lkVideoDidPropertyLabel{
          display: flex;
          justify-content: space-between;
          height: 37px;
          margin-top: 8px;
          // margin-left: 16px;
          // margin-right: 16px;
          // margin-bottom: 16px;
          // flex: 1;
          color: var(--Text-text-special, #7479F6);
          //styleName: Body/Body text medium 14;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          align-items: center;
          &.margined{
            margin-left: 16px;
            margin-right: 16px;
          }
          &.splitter{
            border-bottom: 1px solid var(--Border-border-light, #6F748380);
          }
          &.subtitles{
            margin: 0px;
            // margin-left: -16px;
            
            // margin-top: -8px;
            // margin-bottom: -8px;
            height: 57px;
            background: var(--background-bg-pages, #121724);
            border-radius: 8px;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
          }
        }
        .lkVideoDidProperty{
          display: flex;
          // width: 100%;
          // margin-top: 8px;
          // margin-bottom: 8px;
          // margin-left: 16px;
          // margin-right: 16px;
          flex: 1;
          gap: 8px;
          align-items: center;
          // justify-content: center;
          &.margined{
            margin-left: 16px;
            margin-right: 16px;
          }
          &.vertical{
            flex-direction: column;
          }
          .lkVideoDidPropertySubsring{
            color: var(--Text-text-placeholder, #979AA4);
            //styleName: Body/Body text medium 12;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.63px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

          }
          button{
            flex: 1;
          }
          input{
            flex: 1;
          }
          .lkVideoDidPropertySpan{
            display: flex;
            flex: 1;
            input{
              height: 30px;
            }
            
          }
          .imageuploader{
            display: flex;
            flex: 1;
            .ant-upload{
              flex: 1;
              button{
                justify-content: flex-start;
                flex: 1;
                width: 100%;
              }
            }
          }
          .lkVideoDidPropertyFormat{
            display: flex;
            width: 100%;
            flex-direction: column;
            flex: 1;
            .lkVideoDidPropertyAlert{
              min-height: 25px;
              margin-bottom: 4px;
              margin-top: 4px;
              font-family: Montserrat;
              font-weight: 500;
              font-size: 10px;
              line-height: 12.19px;
              color: var(--Text-text-black);
              flex: 1;
              .alertRed{
                color: var(--Border-border-error);
                padding-right: 8px;
              }
            }
          }
          }
        }
       
    }
  }

  .lkVideoDidResultContainer{
    display: flex;
    flex-direction: column;
    background-color: var(--background-bg-pages);
    .lkVideoDidResultTitle{
      font-family: Montserrat;
      font-weight: 600;
      font-size: 18px;
      line-height: 21.94px;
      letter-spacing: 0%;
      color: var(--Text-text-black, #FDFDFD);
      margin-bottom: 40px;
      margin-top: 16px;
    }
    // .lkVideoDidResults{

    // }
    .lkVideoDidResults{
      display: flex;
      gap: 10px;
      max-width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
    }
    // : var(--Text-text-black, #FDFDFD);

  }
}