.generationImages{
    display: flex;
    width: 100%;
    flex-direction: column;
    background: var(--background-bg-pages, #121724);
    gap: 24px;
    // margin-top: 24px;
    // .generationImagesHeader{
    //     display: flex;
    //     width: 100%;
    //     height: 48px;
    //     justify-content: flex-start;
    //     align-items: center;
    //     gap: 24px;
    //     .generationImagesHeaderImage{
    //         display: flex;
    //     }
        // .generationImagesHeaderText{
        //     display: flex;
        //     //styleName: Body/Body text medium 14;
        //     font-family: Montserrat;
        //     font-size: 14px;
        //     font-weight: 500;
        //     line-height: 21px;
        //     text-align: left;
        //     color: var(--Text-text-black, #121724);

        // }
    // }
    .generationImagesMain{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;
        position: relative;
        .generationImagesMainText{    
            display: flex;
            width: 100%;
            padding: 24px 16px 24px 16px;
            height: 90px;
            gap: 24px;
            border-radius: 16px;
            border: 2px 0px 0px 0px;
            background: var(--background-bg-light, #192033);
            border: 2px solid var(--Border-border-active, #7479F6);
            input{
                width: calc(100% - 101px - 12px);
                height: 32px;
                background: var(--background-bg-light, #192033);
                border: none;
                border-style: none; 
                border-color: Transparent; 
                overflow: auto;
                //styleName: Body/Body text medium 14;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
                color: var(--Text-text-black, #FDFDFD);
            }
            input:focus{
                border: none;
                border-style: none; 
                border-color: Transparent; 
                overflow: auto;
                outline: none;
            }
            input:focus-visible{
                border: none;
                border-style: none; 
                border-color: Transparent; 
                overflow: auto;
                outline: none;
            }
        }
        .generationImagesMainTextBtn{
            display: flex;
            width: 101px;
            position: absolute;
            right: 24px;
            top: 0px;
            height: 90px;
            justify-content: center;
            align-items: center;
            button{
                height: 40px;
                width: 101px;
                padding: 6px 15px 6px 15px;
                gap: 10px;
                border-radius: 6px;
                border: 1px 0px 0px 0px;
                // background: var(--Button-button-active, #7479F6);
                // border: 1px solid var(--Border-border-active, #7479F6);
                color: #ffffff;
                
            }
        }
        .generationImagesMainSettings{
            display: flex;
            width: 100%;
            flex-direction: column;
            background: var(--background-bg-light, #192033);
            border: 1px solid var(--Border-border-light, #6F748380);
            border-radius: 16px;
            // display: none;
            // .ant-collapse-header-text{
            //     max-width: fit-content;
            //     font-family: Montserrat;
            //     min-width: max-content;
            //     font-weight: 600;
            //     margin-inline-end: 0;
            // }
            // .ant-collapse-arrow svg{
            //     transform: rotate(90deg);
            // }
            // .ant-collapse-item-active {
            //     .ant-collapse-arrow svg{
            //         transform: rotate(-90deg) !important;
            //     }
            // }
            .generationImagesMainSettingsHeader{
                display: flex;
                // width: 100%;
                //styleName: Body/Button/Button text SemiBold 12;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                line-height: 14.63px;
                text-align: center;

            }
            // .generationImagesMainSettingsBody{
            //     display: flex;
            //     width: 100%;
            //     flex-direction: column;

            //     .generationImagesMainSettingsLine{
            //         display: flex;
            //         width: 100%;
            //         gap: 24px;
            //     .generationImagesMainSettingContainer{
            //         display: flex;
            //         width: 100%;
            //         flex-direction: column;
            //     }
            //     }
            // }
        }

    }
    .generationImagesHistory{
        display: flex;
        gap: 24px;
        flex-direction: column;
        margin-top: 8px;
        .generationImagesHistoryTitle{
            display: flex;
            //styleName: Title/Text - Title 3;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 600;
            line-height: 21.94px;
            text-align: left;
            height: 38px;
        }
        .generationImagesHistoryBody{
            display: flex;
        }
    }
}