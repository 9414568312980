.deleteDialogContainer{
  display: flex;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000a0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  backdrop-filter: blur(10px);
  .deleteDialog{
    display: flex;
    flex-direction: column;
    width: 448px;
    background: var(--background-bg-light, #192033);
    border-radius: 16px;
    
    .deleteDialogLabelContainer {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid var(--Border-border-light, #6F748380);

      .deleteDialogLabel {
        display: flex;
        padding: 24px 17px;
        font-family: Montserrat;
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0%;
        vertical-align: middle;
        
      }
      .deleteDialogLabelClose {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        cursor: pointer;
      }
    }
    .deleteDialogText {
      display: flex;
      flex-direction: column;
      padding: 24px;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0%;
      color: var(--Text-text-black, #FDFDFD);

    }
    .deleteDialogFooter {
      display: flex;
      padding: 24px;
      gap: 12px;
      justify-content: flex-end;
    }
  }
}