.audioRecognitionHistoryList {
  .audioRecognitionHistoryListLabel {
    width: 100%;
    height: 38px;
    gap: 8px;
    //styleName: Title/Text - Title 3;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    margin-bottom: 16px;
  }
  .chatMessageAudioTextContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 16px;
  }
  .noGenerationsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 24px;
    span {
      display: flex;
    }
    .noGenerationsTitle {
      //styleName: Title/Text - Title 3;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.94px;
      text-align: center;
      color: var(--Text-text-black, #121724);
    }
    .noGenerationsText {
      //styleName: Body/Body text medium 14;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
      color: var(--Text-text-placeholder, #979aa4);
    }
  }
}
