.mjProject{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  .mjProjectHeader{
    display: flex;
    justify-content: space-between;
    background: var(--background-bg-pages, #121724);
    width: 100%;    
    min-height: 37px;
    height:auto;
    padding-top: 8px;
    padding-right: 24px;
    padding-bottom: 8px;
    padding-left: 24px;
    gap: 2px;
    transition: max-height 0.3s ease-out;

    &.clampHeader{
      // height: 37px;
      max-height: 56px;
      // transition: max-height 1s ease-in-out;
      .mjProjectHeaderText{
        display: -webkit-box;
        overflow: hidden;
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
    &.fullHeader{
      max-height: 300px;
      // transition: max-height 1s ease-in-out;
      //height: 300px;
      .mjProjectHeaderText{
        overflow-y: scroll;
      }
    }
   .mjProjectHeaderText{
      cursor: pointer;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0%;
      color: var(--Text-text-special, #7479F6);
    
   }
   .mjProjectHeaderDate{
      color: var(--Text-text-placeholder, #979AA4);
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0%;
      min-width: 120px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
   }

    
  }
  .mjProjectCards{
    display: flex;
    flex-direction: row;
    gap: 24px;
    flex-wrap: wrap;
  }
}