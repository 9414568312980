.lkchatcard {
  display: flex;
  width: 240px;
  height: 74px;
  gap: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;

  .lkchatCardContainer {
    display: flex;
    width: 240px;
    height: 74px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    .lkchatCardLogo {
      display: flex;
      min-width: 40px;
      height: 40px;
      margin: 8px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .lkchatCardTitle {
      display: flex;
      // margin-top: 12px;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.07px;
      text-align: left;
      align-items: center;

      // padding-left: 8px ;
      // padding-right: 8px;
      // margin-top: 8px;
      margin-right: 8px;
    }
  }
  &.dark {
    border: 1px solid var(--Border-border-light, #dbdbe080);
    background: transparent;
    transition: all 0.3s ease;
    &:hover {
      border: 1px solid var(--Border-border-active, #7479F6);
      background: var(--Table-table-item-active, #F3F3FE);
  
    }
    .lkchatCardContainer {
      .lkchatCardLogo {
        border: 4px solid var(--Border-border-light, #4e4e4e80);
      }
      .lkchatCardTitle {
        color: #b4b2b2;
      }
    }
  }
  &.light {
    border: 1px solid var(--Border-border-light, #dbdbe080);
    background: var(--background-bg-light, #fefefe);
    &:hover {
      border: 1px solid var(--Border-border-active, #7479F6);
      background: var(--Table-table-item-active, #F3F3FE);
  
    }
    .lkchatCardContainer {
      .lkchatCardLogo {
        border: 4px solid var(--Border-border-light, #dbdbe080);
      }
      .lkchatCardTitle {
        color: #121724;
      }
    }
  }
}
