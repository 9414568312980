.lkMidjourneyCreateProps {
  display: flex;
  flex-direction: column;
  width: 372;
  height: fit-content;
  gap: 24px;
  border-radius: 16px;
  border-width: 1px;
  padding-top: 16px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  background: var(--background-bg-pages, #121724);
  border: 1px solid var(--Border-border-light, #6F748380);

  .lkMidjourneyCreatePropsLabel{
    display: flex;
    color: var(--Text-text-special, #7479F6);
    font-family: Montserrat;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    letter-spacing: 0%;
    // border-bottom: 2px solid var(--Border-border-active, #7479F6);
    border-bottom: 1px solid var(--Border-border-light, #6F748380);
    align-items: center;
    justify-content: center;
    height: 41px;

  }
  .lkMidjourneyCreateProp{
    display: flex;
    flex-direction: column;
    width: 324;
    gap: 16px;
    border-radius: 8px;
    border-width: 1px;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-left: 16px;
    border: 1px solid var(--Border-border-light, #6F748380);
    background: var(--background-bg-light, #192033);
    // border-bottom: 1px solid var(--Border-border-light, #6F748380);
    
    .lkMidjourneyCreatePropLabel{
      font-family: Montserrat;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0%;
      color: var(--Text-text-special, #7479F6);
      border-bottom: 1px solid var(--Border-border-light, #6F748380)
    }
    .lkMidjourneyCreatePropValue{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  .lkMidjourneyCreateSubmit{
    display: flex;
    flex-direction: column;
    .lkMidjourneyCreateSubmitLabel{
      display: flex;
      font-family: Montserrat;
      font-weight: 500;
      font-size: 12px;
      line-height: 14.63px;
      letter-spacing: 0%;
      color: var(--Text-text-placeholder, #979AA4);

    }

  }

}