.midjourneyDetailViewContainer{
  display: flex;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #000000a0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  backdrop-filter: blur(10px);
  .midjourneyDetail{
    display: flex;
    width: 480;
    border-radius: 16px;
    background: var(--background-bg-light, #192033);
    box-shadow: 0px 4px 22px 0px #0000000F;
    flex-direction: column;
    .midjourneyDetailLabelContainer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      margin-left: 40px;
      margin-right: 40px;
      border-bottom: 2px solid var(--Border-border-light, #6F748380);
      .midjourneyDetailLabeltags{
        display: flex;
        flex-direction: row;
        gap: 8px;
        .midjourneyDetailLabeltag{
          display: flex;
          justify-items: center;
          height: 22;
          border-radius: 6px;
          border-width: 1px;
          gap: 3px;
          padding-top: 4px;
          padding-right: 8px;
          padding-bottom: 4px;
          padding-left: 8px;
          font-family: Montserrat;
          font-weight: 500;
          font-size: 12px;
          line-height: 100%;
          letter-spacing: 0%;
          color: var(--Text-text-black, #FDFDFD);
          background: var(--background-bg-pages, #121724);
          border: 1px solid var(--Border-border-light, #6F748380);
        }
      }
    }
    .midjourneyDetailBody{
      display: flex;
      flex-direction: column;
      margin: 24px 40px 24px 40px;
      .midjourneyDetailBodyTextContainer{
        font-family: Montserrat;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0%;
        margin-bottom: 16px;
      }
      .midjourneyDetailBodyImage{
        width: 400;
        height: 400;
        border-radius: 8px;
        overflow: hidden;
      }

    }

  }


}