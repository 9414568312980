.vizardVideoPreviewCard {
  display: flex;
  width: 236px;
  height: 263px;
  flex-direction: column;
  align-items: center;
  background: var(--background-bg-light, #192033);
  border-radius: 12px;
  padding: 12px 8px;
  .vizardVideoPreviewCardBody{
    display: flex;
    width: 212px;
    height: 212px;
    min-height: 212px;
    align-items: center;
    justify-content: center;    
  }
  .vizardVideoPreviewCardFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 4px;
    margin-top: 12px;
    margin-bottom: 24px;
    div{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: auto;
     width: 100%;
     height: 24px; 
     background: var(--background-bg-pages, #F8F8FA);

    }
  }
}