.lastImageGenerations{
  // margin: 32px 24px 32px 12px;
  width: 100%;
  .chatMessageImageContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    // background: var(--background-bg-light, #FEFEFE);
    margin-bottom: 16px;
    
  }
}
