.LKVideoVizardView {


  .videoVizardViewTag {
    display: flex;
    flex-direction: column;
    padding: 4px 8px 4px 8px;
    border-radius: 6px;
    background: var(--background-bg-pages, #F8F8FA);
    //styleName: Body/Body text medium 12;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    .video-responsive {
      display: flex;
      flex-direction: column;
      
    }

  }

  .LKVideoVizardViewResult{
    margin-top: 24px;
    .LKVideoVizardViewResultTitle {
      //styleName: Title/Text - Title 3;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.94px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--Text-text-black, #121724);
  
    }
  }

}