.faqUserPage{
  display: flex;
  height: 100%;
  width: 100%;
  .emptyPage{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}